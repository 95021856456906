import router from "./router";
import store from "@/store";
import $ from "jquery";

let whiteList = ["/Reserves/login", "/Reserves/pair"];
let errorpage = ''
router.onError(error => {
  console.log(error);
  const targetPath = router.history.pending.fullPath;
  errorpage = targetPath
  router.replace(targetPath);
})
router.beforeEach((to, from, next) => {
  // console.log(from.path, to.path)
  
  document.title = to.meta.title;
  
  if (whiteList.indexOf(to.path) == -1) {
    if (to.query.hash) {
      store.dispatch("getHashInfo", to.query.hash,errorpage);
    }
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if ((from.path == '/' || from.path == '/Reservations') && to.path == "/Reservations/input") {
    if (store.state.jsTag) {
      store.commit(
        "addTag", {
        headStr: store.state.jsTag.coReserveForm.headerTagInInput,
        bodyStr: store.state.jsTag.coReserveForm.tagInInput
      }
      );
    }

  } else if ((from.path == '/' || from.path == '/Reservations/input') && to.path == '/Reservations/confirm') {
    if (store.state.jsTag) {

      store.commit(
        "addTag",
        {
          headStr: store.state.jsTag.coReserveForm.headerTagInConfirm,
          bodyStr: store.state.jsTag.coReserveForm.tagInConfirm
        }
      )
    }
  } else if ((from.path == '/' || from.path == '/Reservations/confirm') && to.path == '/Reservations/save') {
    if (store.state.jsTag) {

      let bodyStr = JSON.parse(JSON.stringify(store.state.jsTag.coReserveForm.tagInSave).split('${reserveNo}').join(store.state.completeRes.reserveCode))
      store.commit(
        "addTag",
        {
          headStr: store.state.jsTag.coReserveForm.headerTagInSave,
          bodyStr
          // tagInSave
        }
      )
    }
    // underBodyTagInSave
  } else if ((from.path == '/' || from.path == '/Reservations/save') && to.path == '/Reservations/complete') {
    if (store.state.jsTag) {

      let bodyStr = JSON.parse(JSON.stringify(store.state.jsTag.coReserveForm.tagInComplete).split('${reserveNo}').join(store.state.completeRes.reserveCode))
      store.commit(
        "addTag",
        {
          headStr: store.state.jsTag.coReserveForm.headerTagInComplete,
          bodyStr
        }
      );
    }
  }

  next();

});


router.afterEach((to, from,) => {
  if (from.path == '/Reserves/login' && to.path == '/Reserves/top') {
    // router.go(0)
  }
  if (from.path == '/Reservations/confirm' && to.path == '/Reservations/complete') {
    // router.go(0)
    console.log(to, 'tototo')
    // router.replace(to.fullPath)
    // window.location.replace(to.fullPath)

  }

})