import Vue from "vue";
import VueRouter from "vue-router";

// const routerPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error => error);
// };
Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: {
      // name: "Reservations"
      path: "/Reservations",
    }
  },
  {
    path: "/Reservations",
    name: "Reservations",
    meta: {
      title: "JIBUN CLINIC"
      // keepAlive:true
    },
    component: resolve =>
      require(["@/pages/Reservations/views/Reservations/Reservations.vue"], resolve)
  },
  {
    path: "/Reservations/input",
    name: "Input",
    meta: {
      title: "JIBUN CLINIC",
      // keepAlive: true
    },
    component: resolve => require(["@/pages/Reservations/views/input/input.vue"], resolve)
  },
  {
    path: "/Reservations/confirm",
    name: "Confirm",
    meta: {
      title: "JIBUN CLINIC"
    },
    component: resolve => require(["@/pages/Reservations/views/confirm/confirm.vue"], resolve)
  },
  {
    path: "/Reservations/save",
    name: "Save",
    meta: {
      title: "JIBUN CLINIC"
    },
    component: resolve => require(["@/pages/Reservations/views/save/save.vue"], resolve)
  },
  {
    path: "/Reservations/complete",
    name: "Completed",
    meta: {
      title: "JIBUN CLINIC"
    },
    component: resolve => require(["@/pages/Reservations/views/complete/complete.vue"], resolve)
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes
});

export default router;
